import React from 'react'
import metaTags from '../config/meta-tags/en.json'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import BlogLayout from '../ui/layouts/BlogLayout'
import NewsItem from '../ui/components/carousels/NewsItem'
import useRevealEffect from '../hooks/effects/useRevealEffect'
import defaultImage from '../assets/images/empty-image-blog.svg'
import { getLocaleFromPathname } from '../libs/i18n'
import { formatPreviewNodes } from '../app/utils/blog'
import blogCategories from '../config/blog.json'

function hydrateTitle(titleKey) {
  const category = blogCategories.find(category => category.key === titleKey)
  if (category) return category.title
  else return titleKey
}

const BlogCategory = ({ pageContext, ...props }) => {
  const locale = getLocaleFromPathname(props.location)
  const panelRef = useRevealEffect()
  const title = pageContext.category
  const items = pageContext.posts
  const blogroll = pageContext.blogroll
  return (
    <>
      <HelmetComponent title={metaTags.blogTitle} description={metaTags.blog} />
      <AppWrapper {...props} lang={locale}>
        <BlogLayout blogroll={blogroll} activePath={pageContext.category}>
          <div className="blog-content" ref={panelRef}>
            <div className="blog-content-section" key={title}>
              <h2 className="blog-content-title">{hydrateTitle(title)}</h2>

              <div className="blog-content-posts">
                {formatPreviewNodes(items, 100).map(item => (
                  <NewsItem
                    internal
                    key={item.summary}
                    href={item.href}
                    alt={item.alt}
                    image={item.image ? item.image : defaultImage}
                    date={item.date}
                    summary={item.summary}
                  />
                ))}
              </div>
            </div>
          </div>
        </BlogLayout>
      </AppWrapper>
    </>
  )
}

export default BlogCategory
